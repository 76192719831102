import Typography from "typography"

const baselineFontConfig = {
  baseFontSize: "18px",
  googleFonts: [
    // { name: "Open Sans", styles: ["700"] },
    // { name: "Montserrat", styles: ["400", "700"] },
  ],
  headerFontFamily: ["Open Sans", "sans-serif"],
  bodyFontFamily: ["Montserrat"],
}

baselineFontConfig.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
  }
}

const typography = new Typography(baselineFontConfig)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
