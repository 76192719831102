import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { rhythm } from "../utils/typography"
import { css } from "@emotion/react"
import Theme from "../utils/theme"

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata
        return (
          <div
            css={css`
              margin-top: -1rem;
              margin-bottom: ${rhythm(1.5)};
              background: white;
              border-radius: 5px;
              color: ${Theme.FontColor};
              padding: 1rem;
            `}
          >
              <p>
                <strong>{author}</strong> is based in Scotland. Love for helping
                people get where they want to go, healthcare, technology, languages, and good data.
              </p>
              <p>
                Find me on {` `}
                <a href={`https://twitter.com/${social.twitter}`}>Twitter</a>,
                {` `}
                <a href={`https://linkedin.com/in/${social.linkedin}`}>
                  LinkedIn
                </a>.
              </p>
          </div>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-ross-j-h.jpg/" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
          linkedin
        }
      }
    }
  }
`

export default Bio
