import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import Bio from "./bio"
import Theme from "../utils/theme"

import { rhythm, scale } from "../utils/typography"

const Breakpoints = {
  SMALL: "420px",
  MEDIUM: "1024px",
}

const Header = styled.header`
  background: ${Theme.HeaderBackgroundColor};
  border-top: 0.5rem solid ${Theme.HeaderBorderColor};
  color: ${Theme.HeaderFontColor};
`

const boundaryCss = css`
  @media (min-width: ${Breakpoints.SMALL}) {
    max-width: ${rhythm(24)};
  }
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1px;
  border-bottom: 10px solid ${Theme.HeaderBorderColor};
`
const titleCss = css`
  ${scale(1.25)};
  margin-top: 0;
  margin-bottom: 0;
  padding: ${rhythm(0.5)} ${rhythm(1 / 2)};
`

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <div css={boundaryCss}>
          <h1 css={titleCss}>
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              {title}
            </Link>
          </h1>
        </div>
      )
    } else {
      header = (
        <div css={boundaryCss}>
          <h3 css={titleCss}>
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              {title}
            </Link>
          </h3>
        </div>
      )
    }
    return (
      <div
        css={css`
          background: #f3f3f3;
        `}
      >
        <Header>{header}</Header>
        <div
          css={css`
            margin-left: auto;
            margin-right: auto;
            max-width: ${rhythm(24)};
            padding: ${rhythm(1.5)} ${rhythm(3 / 4)};
          `}
        >
          <main>{children}</main>
        </div>
        <footer
          css={css`
            background: #d93738;
            color: white;
            padding: 0 ${rhythm(1.5)};
            a {
              text-decoration: underline;
            }
          `}
        >
          <div
            css={css`
              margin-left: auto;
              margin-right: auto;
              padding-top: 1px;
              padding-bottom: 1px;
              max-width: ${rhythm(24)};
            `}
          >
            <Bio />
            <p
              css={css`
                font-weight: 600;
              `}
            >
              © {new Date().getFullYear()} Ross J. Hagan
            </p>
          </div>
        </footer>
      </div>
    )
  }
}

export default Layout
