const Colors = {
  PrimaryDark: "#042F59",
  PrimaryLight: "#F3F3F3",
  FontDefault: "#2f3640",
  AccentDark: "#004C8A",
}

const Theme = {
  Colors,
  FontColor: Colors.FontDefault,
  HeaderBackgroundColor: Colors.PrimaryLight,
  HeaderBorderColor: Colors.AccentDark,
  HeaderFontColor: Colors.PrimaryDark,
}

export default Theme
